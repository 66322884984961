import ActivationEvent from '@adobe-fonts/site/events/ActivationEvent.js';
import { fetchCCXSeasonalTemplates, fetchCCXTemplatesFontMetaData } from '@adobe-fonts/site/lib/CCX.js';
import { getActiveIds } from '@adobe-fonts/site/lib/FontSync.js';
import { hasPaidCreativeCloud } from '../util/user_info.js';
import { handleActivationEvent, initI18n } from '../util/activation_util.js';
import { getService as service } from '../util/angular_helpers.js';
import { fetchRecommendedFonts } from '../util/recommendations.js';
import { getUserInfoSync } from '../util/user_info.js';
import { addPageAction, setCustomAttribute } from '../util/new_relic.js';

function init() {
  initI18n();
  loadHiddenGems();
  loadNewestFonts();
  loadRecommendedTemplates();
  loadTrendingFonts();
  loadNewFromMonotypeFonts();

  // The analytics service relies on the legacy Angular app, so we need to wait
  // until that's loaded before we can initialize it.
  window.addEventListener('DOMContentLoaded', () => {
    initAnalyticsEvents();
  });

  document.body.addEventListener(
      ActivationEvent.EVENT_NAME, handleActivationEvent);
}

function initAnalyticsEvents() {
  // Initialize notification click tracker
  const NewrelicClickTrackerService = service('NewrelicClickTrackerService');
  NewrelicClickTrackerService.init({page: 'crossbar_homepage'});

  // Unload event
  window.addEventListener('beforeunload', function() {
    addPageAction('typekit.crossbar_homepage.page_exit');
  });
}

function hideElementItemsByID(elementIds) {
  for (const id of elementIds) {
    const el = document.querySelector(id);
    if (el) {
      el.style.display = 'none';
    }
  }
}

function loadNewFromMonotypeFonts() {
  fetchRecommendedFonts({
    familiesPerPage: 16,
    isPersonalized: false,
    page: 1,
    type: 'new_from_monotype'
  }).then(items => {
    setElementItems(
      document.querySelector('#home-new-from-monotype-fonts'), items);
  });
}


function loadHiddenGems() {
  fetchRecommendedFonts({
    familiesPerPage: 16,
    isPersonalized: false,
    page: 1,

    type: 'hidden_gems'
  }).then(items => {
    setElementItems(
      document.querySelector('#home-hidden-gems-fonts'), items);
  });
}

function loadNewestFonts() {
  fetchRecommendedFonts({
    familiesPerPage: 16,
    isPersonalized: false,
    page: 1,
    type: 'newest'
  }).then(items => {
    setElementItems(
      document.querySelector('#home-newest-fonts'), items);
  });
}

async function loadRecommendedTemplates() {
  const limit = 40;
  const offset = 0;
  let templatesCarouselID = '#home-ccx-templates';

  // Check feature flag for logged-in users
  const FloodgateService = service('FloodgateService');

  if (FloodgateService) {
    await FloodgateService.load();
    const shouldShowTemplatesAtTop = FloodgateService.isReleaseEnabled('Typekit_ccx_templates_first');

    if (shouldShowTemplatesAtTop) {
      document.body.classList.add('ccx-top-test-visible');
      templatesCarouselID = '#home-ccx-templates-top';

      // Setup custom NR event
      setCustomAttribute('enabledABTest', 'CCXTemplatesFirst');
    }
  }

  const templatesCarousel =
      document.querySelector(templatesCarouselID);

  if (!templatesCarousel) {
    return;
  }

  const { isLoggedIn } = getUserInfoSync();
  if (isLoggedIn) {
    getActiveIds().then(activeIds => {
      templatesCarousel.activeIds = activeIds;
    }).catch(() => {
      //  No, op, couldn't find active Ids
    });
  }

  fetchCCXSeasonalTemplates(offset, limit, hasPaidCreativeCloud(), isLoggedIn)
      .then(data => fetchCCXTemplatesFontMetaData(data))
      .then(templates => {
        setElementItems(
            document.querySelector(templatesCarouselID), templates);
      })
      .catch(error => {
        console.log(`[Crossbar]: Failed to load ${error}`);
        hideElementItemsByID(['#home-browse-by-templates-title',
          '#home-browse-by-templates-subtitle',
          templatesCarouselID]);
      });
}

function loadTrendingFonts() {
  fetchRecommendedFonts({
    familiesPerPage: 16,
    isPersonalized: false,

    page: 1,
    type: 'trending'
  }).then(items => {
    setElementItems(
      document.querySelector('#home-trending-fonts'), items);
  });
}

function setElementItems(element, items) {
  if (!element) {
    return;
  }

  element.items = items;
}

export { init };
